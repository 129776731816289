import React, { useEffect, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Table, Button, message, Spin, Progress } from 'antd';
import { getBackups } from '../../services/backup';
import { useSelector } from 'react-redux';

const BackupList = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [backups, setBackups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [fetchError, setFetchError] = useState(false);

  useEffect(() => {
    const fetchBackups = async () => {
      try {
        const backupData = await getBackups();
        setBackups(backupData.data);
        setFetchError(false);
      } catch (error) {
        message.error('Failed to fetch backups');
        setFetchError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchBackups();
  }, []);

  const columns = [
    {
      title: 'Download',
      dataIndex: 'path',
      key: 'path',
      render: (path, record) => (
        <Button
          onClick={() => handleDownload(record.path)}
          type="primary"
          icon={<DownloadOutlined />}
          disabled={!path || downloading}
        >
          Download
          {/*{downloading ? 'Downloading...' : 'Download'}*/}
        </Button>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
  ];

  const handleDownload = async (downloadUrl) => {
    setDownloading(true);
    setDownloadProgress(0); // Reset progress

    try {
      const response = await fetch(downloadUrl, {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const contentLength = response.headers.get('Content-Length'); // Get total file size
      const total = parseInt(contentLength, 10);

      let loaded = 0;
      const fileName = downloadUrl.split('/').pop();
      const reader = response.body.getReader();

      const stream = new ReadableStream({
        async start(controller) {
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            loaded += value.length; // Update loaded bytes
            const progress = Math.round((loaded / total) * 100); // Calculate progress percentage
            setDownloadProgress(progress); // Update progress state

            controller.enqueue(value);
          }
          controller.close();
        },
      });

      const blob = await new Response(stream).blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(link.href);

      message.success('Backup downloaded successfully!');
    } catch (error) {
      console.error('Error downloading file:', error);
      message.error('Failed to download backup');
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div>
    {downloading && (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Spin tip="Downloading backup..." />
          <Progress
            percent={downloadProgress}
            status="active"
            showInfo
            format={(percent) => `${percent}%`}
          />
        </div>
      )}
    
      <Spin tip="Loading backups..." spinning={loading}>
        {!loading && !fetchError && (
          <Table
            columns={columns}
            dataSource={backups}
            rowKey={(record) => record.path}
          />
        )}
        {fetchError && <div>Failed to load backups. Please try again later.</div>}
      </Spin>

      
    </div>
  );
};

export default BackupList;
