import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  message,
  Col,
  Card,
  notification,
  Tabs,
  Upload,
} from 'antd';
import {
  MinusCircleOutlined,
  FileTextOutlined,
  PlusOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import userService from '../../services/user';
import equipmentService from '../../services/equipments';
import equipmentRequestService from '../../services/equipmentRequest';
import DefaultLayout from '../../components/layout/DefaultLayout';
import Header from '../../components/header';
import './index.css';
import InventoryAssetComponent from '../../components/InventoryAsset';
import EquipmentCalculatComponent from '../../components/EquipmentCalculate';
import CostCenterComponent from '../../components/costCenter';
import DepartmentComponent from '../../components/department';
import ServiceComponent from '../../components/service';
import TeamComponent from '../../components/team';

import CompanyComponent from '../../components/company';

import SdfPriorityComponent from '../../components/sdfPriority';




const PostEquipmentRequest = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { currentUser } = useSelector((state) => state.user);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editingEquipmentRequest, setEditingEquipmentRequest] = useState(null);
  const [isDraft, setIsDraft] = useState(null);
  const defaultLocationId = currentUser ? currentUser.location.id : null;
  const defaultDeparmentId = currentUser ? currentUser.department.id : null;
  // const defaultCompanyId = currentUser ? currentUser.department.id : null;

  const { TabPane } = Tabs;
  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const [fileList, setFileList] = useState([]);

  const [editInvMode, setEditInvMode] = useState(false);
  const [serviceIds, setServiceIds] = useState(null);
  const [teamIds, setTeamIds] = useState(null);
  

  // CALCULATING THE EXPENSE RATE
  const calculatedExpRate = (event, indexx, assetIndexx) => {
    const amt = Number(form.getFieldValue(['equipment_requests',indexx,'amount']));
    const rate = Number(form.getFieldValue(['equipment_requests',indexx,'rate']));
    const res = amt*rate*Number(event.target.value);

    const currentValues = form.getFieldsValue();


    const updatedValues = {
      ...currentValues,  
      equipment_requests: currentValues.equipment_requests.map((request, requestIndex) => {
        if (requestIndex === indexx) {  
          return {
            ...request,  
            asset_details: request.asset_details.map((detail, detailIndex) => {
              if (detailIndex === assetIndexx) {  
                return {
                  ...detail,  
                  expected_expense: res,  
                };
              }
              return detail;  
            }),
          };
        }
        return request;  
      }),
    };

    form.setFieldsValue(updatedValues);

  };
  // CALCULATING THE EXPENSE RATE
  


  const { id } = useParams();
  const navigate = useNavigate();

  const handleRequirementChange = (index, values) => {
    setSelectedCheckboxes((prev) => {
      const newCheckboxes = [...prev];
      newCheckboxes[index] = values;
      return newCheckboxes;
    });
  };

  const handleServiceChange = (values) => {
    // form.setFieldValue('service_ids', values);
    setServiceIds(values); 
  };
  const handleTeamChange = (values) => {
    // form.setFieldValue('team_ids', values);
    setTeamIds(values); 
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          setLoading(true);
          setEditMode(true);
          const response = await equipmentRequestService.getEquipmentsRequestById(id);
          // console.log(response);

          const userArray = [];
          if (response?.approvers) {
            response.approvers.forEach((approver) => {
              if (approver?.users) {
                userArray.push(...approver.users); 
              }
            });
          }
          const editableContition = true;  
          const currentId = currentUser.user_id;  
          const editRights = userArray.find(editRights => editRights.id === currentId  && editRights.editable === true && editRights.status == 'Processing');

          if (response && response.status === 'Pending' && editRights != null && editRights !== false) {
            setEditInvMode(true)
          }
          
          if (
            (response && response.draft_at !== null) ||
            (response && response.status === 'Return') ||
            (response && response.status !== 'Approved') ||
            (response && response.status !== 'Disapproved')
          ) {

            //setEditMode(true);
            setEditingEquipmentRequest(response);
            setLoading(false);
            // console.log('yeh response ', response);


            form.setFieldsValue({
              request_title: response.request_title,
              location_id: response.location.id,
              department_id: response.department.id,
              cost_center_id: response.cost_center_id.id,
            });


            if (response && response.equipmentRequests && response.equipmentRequests.length !== 0) {
              form.setFieldsValue({
                equipment_requests: response.equipmentRequests.map(
                  (equipmentRequest) => ({
                    equipment_id: equipmentRequest.equipment.id,
                    quantity: equipmentRequest.qty,
                    state: equipmentRequest.state.id,
                    expense_type: equipmentRequest.expense_type.id,
                    expense_nature: equipmentRequest.expense_nature.id,
                    business_justification: equipmentRequest.business_justification,
                    currency: equipmentRequest.currency,
                    amount: equipmentRequest.amount,
                    rate: equipmentRequest.rate,
                    asset_details: equipmentRequest.asset_details && JSON.parse(equipmentRequest.asset_details).map((assetDetail) => ({
                      action: assetDetail.action,
                      request_type: assetDetail.request_type,
                      inventory_status: assetDetail.inventory_status,
                      qty_inventory: assetDetail.qty_inventory,
                      expected_expense: assetDetail.expected_expense,
                      serial_no: assetDetail.serial_no,
                      asset_code: assetDetail.asset_code,
                      description: assetDetail.description,
                      remarks: assetDetail.remarks,
                    })),
                  })
                ),
              });
            }
            
            if (
              response &&
              response.softwareRequests &&
              response.softwareRequests.length !== 0
            ) {
              form.setFieldsValue({
                software_requests: response.softwareRequests.map(
                  (softwareRequest) => ({
                    software_name: softwareRequest.name,
                    version: softwareRequest.version,
                    quantity: softwareRequest.qty,
                    expense_type: softwareRequest.expense_type.id,
                    expense_nature: softwareRequest.expense_nature.id,
                    business_justification: softwareRequest.business_justification,
                    currency: softwareRequest.currency,
                    amount: softwareRequest.amount,
                    rate: softwareRequest.rate,
                    asset_details: softwareRequest.asset_details && JSON.parse(softwareRequest.asset_details).map((assetDetail) => ({
                      action: assetDetail.action,
                      request_type: assetDetail.request_type,
                      inventory_status: assetDetail.inventory_status,
                      qty_inventory: assetDetail.qty_inventory,
                      expected_expense: assetDetail.expected_expense,
                      serial_no: assetDetail.serial_no,
                      asset_code: assetDetail.asset_code,
                      description: assetDetail.description,
                      remarks: assetDetail.remarks,
                    })),
                  })
                ),
              });
            }
            if (
              response &&
              response.serviceRequests &&
              response.serviceRequests.length !== 0
            ) {
              form.setFieldsValue({
                service_requests: response.serviceRequests.map(
                  (serviceRequest) => ({
                    service_name: serviceRequest.name,
                    state: serviceRequest.state.id,
                    expense_type: serviceRequest.expense_type.id,
                    expense_nature: serviceRequest.expense_nature.id,
                    business_justification: serviceRequest.business_justification,
                    currency: serviceRequest.currency,
                    amount: serviceRequest.amount,
                    rate: serviceRequest.rate,
                    asset_details: serviceRequest.asset_details && JSON.parse(serviceRequest.asset_details).map((assetDetail) => ({
                      action: assetDetail.action,
                      request_type: assetDetail.request_type,
                      inventory_status: assetDetail.inventory_status,
                      qty_inventory: assetDetail.qty_inventory,
                      expected_expense: assetDetail.expected_expense,
                      serial_no: assetDetail.serial_no,
                      asset_code: assetDetail.asset_code,
                      description: assetDetail.description,
                      remarks: assetDetail.remarks,
                    })),
                  })
                ),
              });
            }
          } else {
            navigate('/crf');
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 500) {
          navigate('/crf');
        }
        else {
          console.log(error);
        notification.error({
          message: 'Error fetching Support Desk Form',
          description: error,
        });
        }
        
      }
    };
    fetchData();
  }, [id, form]);



  // For Image
    const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      handleRemoveAttachment(file);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList((prevFileList) => {
        return prevFileList ? [...prevFileList, file] : [file];
      });
      return false;
    },
    fileList,
  };
  const handleRemoveAttachment = (file) => {
    setFileList((prevFileList) => prevFileList.filter((f) => f.uid !== file.uid));
    console.log('file ',file);

    if (file.url) {
      setDeletedAttachments((prevDeleted) => [...prevDeleted, file.key]);
      console.log('send file.key  ',file.key);
    }
  };



  const convertAssetDetailsToString = (assetDetailsArray) => {
    return JSON.stringify(assetDetailsArray.map(assetDetail => ({
      request_type: assetDetail.request_type,
      action: assetDetail.action,
      inventory_status: assetDetail.inventory_status,
      qty_inventory: assetDetail.qty_inventory,
      expected_expense: assetDetail.expected_expense,
      serial_no: assetDetail.serial_no,
      asset_code: assetDetail.asset_code,
      description: assetDetail.description,
      remarks: assetDetail.remarks,
    })));
  };
  const onFinish = async (values) => {

    const { equipment_requests, software_requests, service_requests } = values;
    if (
      (!equipment_requests || equipment_requests.length === 0) &&
      (!software_requests || software_requests.length === 0) &&
      (!service_requests || service_requests.length === 0)
    ) {
      message.error('Please fill out at least one form (equipment, software, or service request)');
      return;
    }

    try {
      values.save_as_draft = String(isDraft);
      //console.log(values)
      
      const equipmentRequestsArray = Array.isArray(values.equipment_requests) ? values.equipment_requests : [];
      const formattedEquipmentRequestsArray = equipmentRequestsArray.map(equipmentRequest => ({
        ...equipmentRequest,
        asset_details: convertAssetDetailsToString(equipmentRequest?.asset_details || []),
      }));

      const softwareRequestsArray = Array.isArray(values.software_requests) ? values.software_requests : [];
      const formattedSoftwareRequestsArray = softwareRequestsArray.length > 0
        ? softwareRequestsArray.map(softwareRequest => ({
          ...softwareRequest,
          asset_details: convertAssetDetailsToString(softwareRequest?.asset_details || []),
        }))
        : null;

      const serviceRequestsArray = Array.isArray(values.service_requests) ? values.service_requests : [];
      const formattedServiceRequestsArray = serviceRequestsArray.length > 0
        ? serviceRequestsArray.map(serviceRequest => ({
          ...serviceRequest,
          asset_details: convertAssetDetailsToString(serviceRequest?.asset_details || []),
        }))
        : null;

      const formattedData = {
        ...values, 
        equipment_requests: formattedEquipmentRequestsArray,
        software_requests: formattedSoftwareRequestsArray,
        service_requests: formattedServiceRequestsArray,
        save_as_draft: values.save_as_draft,
      };

      //console.log(JSON.stringify(formattedData))

      if (editMode) {
        const response = await equipmentRequestService.updateEquipmentRequest(
          editingEquipmentRequest.id,
          formattedData
        );

        if (response.success) {
          setEditMode(false);
          setEditingEquipmentRequest(null);
          notification.success({
            message: 'Equipment Updated',
            description: response.message,
            
          });
          navigate('/crf');
        }
      } else {
        const response = await equipmentRequestService.postEquipmentRequest(
          formattedData
        );

        if (response.success) {
          form.resetFields();
          notification.success({
            message: 'Equipment Added',
            description: response.message,
          });
          navigate('/crf');
        }
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Equipment Operation Failed',
        description: error.response.data.message,
      });
    }
  };

  const getAllEquipmentData = async () => {
    try {
      const response = await equipmentService.getAllEquipments();
      setEquipmentData(response);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response,
      });
    }
  };

  useEffect(() => {
    getAllEquipmentData();
  }, []);

  const getCurrentUser = async () => {
    setLoading(true);
    try {
      const response = await userService.getCurrentUser(currentUser.user_id);
      //console.log(response);
      form.setFieldsValue({
        name: response.name,
        email: response.email,
        designation_id: response.designation.name,
        employee_no: response.employee_no,
        employee_type: response.employee_type,
        extension: response.extension,
        phone_number: response.phone_number,
        profile_location: response.location.name,
        role_id: response.roles.map((role) => role.id),
        // department_id: response.department.name,
        section_id: response.section.name,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const validateCheckboxGroup = (_, values) => {
    if (values && values.length > 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please select at least one requirement.'));
  };

  useEffect(() => {
    getCurrentUser();
  }, []);


  console.log('editingEquipmentRequest');
  let initiatorUser = false;
  initiatorUser = 
    editingEquipmentRequest?.created_by?.id != null && 
    currentUser?.user_id != null && 
    editingEquipmentRequest.created_by.id === currentUser.user_id;

  console.log(initiatorUser); 

  const csValue = editingEquipmentRequest?.cost_center_id?.id != null 
      ? editingEquipmentRequest.cost_center_id.id 
      : 0;
  console.log(csValue); 




  return (
    <DefaultLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Header icon={<FileTextOutlined />} title="Support Desk Form" />
        </Col>
      </Row>
      <Card>
        <Form
          autoComplete="off"
          onFinish={onFinish}
          form={form}
          layout="vertical"
          scrollToFirstError
          className="mt-20"
        >
          <div spinning={loading}>
           

            <Card size="small" className="mb-10" title="Add Service Desk">
              <Row gutter={[12, 12]}>
                <Col lg={8} md={8} sm={24} xs={24}>
                  <Form.Item
                    name="request_title"
                    label="Query Title"
                    rules={[{ required: true, message: 'Type Query Title' }]}
                  >
                    <Input placeholder="Type Query Title" size="large" />
                  </Form.Item>
                </Col>
                <Col lg={8} md={8} sm={24} xs={24}>
                  <Form.Item
                    name="sap_id"
                    label="SAP ID / Windows ID"
                        rules={[{ required: true, message: 'Please select a SAP ID / Windows ID' }]}
                  >
                    <Input
                      placeholder="Type SAP ID / Windows ID:"
                      size="large"
                    />
                  </Form.Item>
                </Col>

                <Col lg={8} md={8} sm={24} xs={24}>
                  <Form.Item
                    name="priority"
                    label="Priority"
                    rules={[{ required: true, message: 'Please select a Priority' }]}
                  >
                    <SdfPriorityComponent/>
                  </Form.Item>
                </Col>

              </Row>
              <Row gutter={[12, 12]}>
              
                <Col lg={8} md={8} sm={24} xs={24}>
                  <Form.Item
                  name="department_id"
                  label="Service Required From Department"
                  initialValue={defaultDeparmentId}
                  rules={[
                    { required: true, message: 'Please select a Service Required From Department' },
                  ]}>
                
                    <DepartmentComponent all={true} />
                </Form.Item>
                </Col>
                <Col lg={8} md={8} sm={24} xs={24}>
                  <Form.Item
                  name="service_id"
                  label="Service Required"
                  initialValue={defaultDeparmentId}
                  rules={[
                    { required: true, message: 'Please select a Service' },
                  ]}>
                    <ServiceComponent  onChange={handleServiceChange} />
                </Form.Item>
                </Col>
                <Col lg={8} md={8} sm={24} xs={24}>
                  <Form.Item
                  name="team_id"
                  label="Team Required"
                  initialValue={defaultDeparmentId}
                  rules={[
                    { required: true, message: 'Please Team Required' },
                  ]}>
                
                    <TeamComponent  onChange={handleTeamChange} />
                </Form.Item>
                </Col>
                

                
              </Row>

              <Row gutter={[12, 12]}>
              <Col lg={8} md={8} sm={24} xs={24}>
                  <Form.Item
                    name="phone"
                    label="Extension / Phone"
                        rules={[{ required: true, message: 'Please select a Extension / Phone' }]}
                  >
                    <Input
                      placeholder="Type Extension / Phone:"
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col lg={24} md={24} sm={24} xs={24}>
                 <Form.Item
                    name="description"
                    label="Description"
                    // rules={[{ required: true, message: 'Type Business Justification' }]}
                  >
                    <Input.TextArea
                      rows={4}
                      size="large"
                      placeholder={`Enter Description`}
                    />
                  </Form.Item>
                </Col>
               
                
              </Row>

            </Card>


  

           {/* ATTACHMENT */}
           <Card size="small" className="mb-10" title="Attachment(s)">
              <Row gutter={[12, 12]}>
               <Col span={24} className="mb-40">
                  <Upload.Dragger multiple {...props} onRemove={(file) => handleRemoveAttachment(file)} >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag files to this area to upload
                    </p>
                  </Upload.Dragger>
                </Col>
              </Row>
            </Card>
            {/* ATTACHMENT */}

            <Row gutter={[12, 12]} className="mt-40">
              <Col span={24} className="text-right">
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  className="mt-24"
                >
                  <Button
                    type="primary"
                    block
                    disabled={loading} 
                    loading={loading}
                    className="btn-blue mr-20"
                    onClick={() => {
                      setIsDraft(false);
                      form.submit();
                    }}
                  >
                    Save & Publish
                  </Button>


                  {!editMode ? (
                      <Button
                      disabled={loading} 
                        loading={loading}
                        type="primary"
                        onClick={() => {
                          setIsDraft(true);
                          form.submit();
                        }}
                      >
                        Save Draft
                      </Button>
                  ) : initiatorUser ? (
                      <Button
                      disabled={loading} 
                        loading={loading}
                        type="primary"
                        onClick={() => {
                          setIsDraft(true);
                          form.submit();
                        }}
                      >
                        Save Draft
                      </Button>
                  ) : ""}
                  

                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </Card>
    </DefaultLayout>
  );
};

export default PostEquipmentRequest;
